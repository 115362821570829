const controls = document.querySelectorAll('[data-dropdown-control]')
const dropdowns = document.querySelectorAll('[data-dropdown]')

let timer = null
let isHoverEvent = false

document.addEventListener('click', click)
document.addEventListener('keydown', keydown)

dropdowns.forEach(checkMediaQueries)
controls.forEach(addMouseEventListeners)

function pointerenter(e) {
  if (e.pointerType !== 'mouse' || !e.target.closest('[data-hover]')) return

  const $control = e.target.querySelector('[data-dropdown-control]')
  const $globalParent = e.target.closest('[data-dropdown]')
  const $openDropdown = $globalParent.querySelector('[data-dropdown-control][aria-expanded="true"]')

  isHoverEvent = true

  if (timer && e.target.querySelector('[data-dropdown-control][aria-expanded="true"]')) {
    clearTimer()
    return
  }

  if ($openDropdown) {
    clearTimer()
    close($openDropdown)
  }

  open($control)
}

function pointerleave(e) {
  if (e.pointerType !== 'mouse' || !e.target.closest('[data-hover]')) return

  const $control = e.target.querySelector('[data-dropdown-control]')

  isHoverEvent = false

  if ($control.getAttribute('aria-expanded') === 'false') return

  timer = setTimeout(close, 1000, $control)
}

function keydown(e) {
  if (e.key === 'Escape') {
    const $openDropdown = document.querySelector('[data-dropdown-control][aria-expanded="true"]')

    if (timer) clearTimer()

    if ($openDropdown) close($openDropdown)
  }
}

function click(e) {
  if (isHoverEvent) return

  const $openDropdown = document.querySelector('[data-dropdown-control][aria-expanded="true"]')

  if (e.target.closest('[data-dropdown-control]')) {
    const $control = e.target.closest('[data-dropdown-control]')

    toggle($control)
    return
  }

  if ($openDropdown && !e.target.closest(`#${$openDropdown.getAttribute('aria-controls')}`)) {
    if (timer) clearTimer()
    close($openDropdown)
    return
  }
}

function toggle($control) {
  const $globalParent = $control.closest('[data-dropdown]')
  const $openDropdown = $globalParent.querySelector('[data-dropdown-control][aria-expanded="true"]')

  if ($control.getAttribute('aria-expanded') === 'true') {
    close($control)
    return
  }
  if ($openDropdown) close($openDropdown)
  open($control)
}

function open($control) {
  const $dropdownList = document.querySelector(`#${$control.getAttribute('aria-controls')}`)

  addMaxHeight($dropdownList)

  $control.setAttribute('aria-expanded', true)
  $dropdownList.setAttribute('aria-hidden', false)
}

function close($control) {
  const $dropdownList = document.querySelector(`#${$control.getAttribute('aria-controls')}`)

  $control.setAttribute('aria-expanded', false)
  $dropdownList.setAttribute('aria-hidden', true)
}

function clearTimer() {
  clearTimeout(timer)
  timer = null
}

function addMaxHeight($dropdownList) {
  $dropdownList.style.cssText = `--max-height: ${$dropdownList.scrollHeight / 16}rem`
}

function checkMediaQueries($dropdown) {
  if (!$dropdown.getAttribute('data-media-hover')) return

  const mediaQuery = getMediaQuery($dropdown)

  mediaQuery.addListener(() => changeDataHover($dropdown, mediaQuery))
  changeDataHover($dropdown, mediaQuery)
}

function getMediaQuery($dropdown) {
  const mediaArray = $dropdown.dataset.mediaHover.split(', ')
  const mediaValue = `(${mediaArray[0]}-width: ${+mediaArray[1] / 16}rem)`

  return window.matchMedia(mediaValue)
}

function changeDataHover($dropdown, mediaQuery) {
  if (mediaQuery.matches) {
    $dropdown.setAttribute('data-hover', true)
    return
  }
  $dropdown.removeAttribute('data-hover')
}

function addMouseEventListeners($control) {
  const $parent = $control.parentElement

  $parent.addEventListener('pointerenter', pointerenter)
  $parent.addEventListener('pointerleave', pointerleave)
}
